module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["null"],"pluginConfig":{"head":true,"exclude":["/preview?**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HIGHBOURNE GROUP","short_name":"HIGHBOURNE GROUP","start_url":"/","display":"standalone","icon":"src/assets/sc/favicon/favicon_16.svg","icons":[{"src":"src/assets/sc/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/sc/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/sc/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/sc/favicon/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/assets/sc/favicon/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/assets/sc/favicon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/assets/sc/favicon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/sc/favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/assets/sc/favicon/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/sc/favicon/ms-icon-70x70.png","sizes":"70x70","type":"image/png"},{"src":"src/assets/sc/favicon/ms-icon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"src/assets/sc/favicon/ms-icon-310x310.png","sizes":"310x310","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dec14e88cfaee5e3f3daa0be1dc25b7b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://graphql.contentful.com/content/v1/spaces/5due6tf43qh9/environments/master"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
